import React, { useEffect, useState } from 'react';
import { getDateFormatForHead, getDateFormatForNews, getDateFormatZulu } from "helpers";
import Head from "next/head";
export const MetaTagsNews = ({
  keywords,
  lastModified,
  metas = [],
  metadata,
  publishedDate,
  relatedImageUrl,
  sectionId,
  sectionUrl,
  sections,
  subtitle,
  summary,
  title,
  relAmp,
  url,
  shared = [],
  type,
  authors,
  createdBy = []
}) => {
  const urlDomain = {
    ole: process.env.NEXT_PUBLIC_DOMAIN_URL_OLE,
    clarin: process.env.NEXT_PUBLIC_DOMAIN_URL,
  };

  let author;

  if (Array.isArray(authors) && authors.length > 0 && authors[0] && authors[0].name) {
    author = authors[0].name;
  } else {
    author = "Redacción Clarín";
  }

  let urlCanonical = shared.length !== 0 ? urlDomain[shared.product] + shared.url : urlDomain[process.env.NEXT_PUBLIC_PROJECT] + url;
  const sectionUrlSplit = sectionUrl.split('/');
  const subSection = sectionUrlSplit[1] || sectionUrlSplit[0];

  const sectionName = sections[0].name ? sections[0].name : "Clarín";
  //No mostrar RSS si la sección es Recetas
  const shouldShowRSSLink = sectionName && !sectionName.includes("Recetas");

  summary = type === "recipe"
    ? `Explora nuestras recetas de ${subSection} en Clarín y descubre una amplia variedad de delicias para satisfacer todos los paladares. Desde clásicos hasta creaciones innovadoras, encuentra inspiración para endulzar tus momentos especiales.`
    : (summary?.replace(/<\/?[^>]+(>|$)/g, "").replace(/'/g, ''))
    ;

  const [width, height] = relatedImageUrl.match(/\d+x\d+/)?.[0]?.split('x').map(Number) || [320, 180];

  return (
    <Head>
      <title>{title}</title>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
      />
      <meta name="title" content={title} />
      <meta name="author" content={author} />
      {createdBy?.encryptedCreatorName &&
        <meta property="mrf:authors" encrypted="true" content={createdBy?.encryptedCreatorName} />
      }
      <meta name="description" content={summary ? summary : "Las novedades más importantes. Leé las Noticias de Hoy en Clarín. Conocé las Últimas noticias de Argentina y del mundo, información actualizada las 24 horas y en español"} />
      <meta name="date" content={getDateFormatZulu(publishedDate) ? getDateFormatZulu(publishedDate) : getDateFormatForHead(publishedDate)} />
      <meta
        name="DC.date.issued"
        content={getDateFormatZulu(publishedDate) ? getDateFormatZulu(publishedDate) : getDateFormatForHead(publishedDate)}
      />
      {keywords && <meta name="Keywords" content={keywords} />}
      {keywords && <meta name="news_keywords" content={keywords} />}
      <meta name="distribution" content="global" />
      <meta name="robots" content="max-image-preview:large" />
      <meta property="twitter:title" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:image" content={relatedImageUrl} />
      <meta
        property="twitter:url"
        content={process.env.NEXT_PUBLIC_DOMAIN_URL + url}
      />
      <meta property="twitter:description" content={summary ? summary : "Las novedades más importantes. Leé las Noticias de Hoy en Clarín. Conocé las Últimas noticias de Argentina y del mundo, información actualizada las 24 horas y en español"} />
      <meta name="ageaParse:gca-categories" content={sectionUrl} />
      <meta
        name="ageaParse:recs:publishtime"
        content={getDateFormatZulu(publishedDate) ? getDateFormatZulu(publishedDate) : getDateFormatForHead(publishedDate)}
      />
      <meta name="taboola-categories" content={sectionUrl} />
      <meta name="volanta" content={subtitle} />
      <meta property="article:section" content={sectionUrl} />
      <meta
        property="article:published_time"
        content={getDateFormatZulu(publishedDate) ? getDateFormatZulu(publishedDate) : getDateFormatForHead(publishedDate)}
      />
      <meta
        property="article:modified_time"
        content={getDateFormatZulu(lastModified) ? getDateFormatZulu(lastModified) : getDateFormatForHead(lastModified)}
      />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="Clarín" />
      <meta property="fb:app_id" content="344479319002359" />
      <meta property="og_locale" content="es_LA" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={summary ? summary : "Las novedades más importantes. Leé las Noticias de Hoy en Clarín. Conocé las Últimas noticias de Argentina y del mundo, información actualizada las 24 horas y en español"} />

      <meta property="og:image" content={relatedImageUrl} />
      <meta property="og:image:width" content={width} />
      <meta property="og:image:height" content={height} />

      <meta
        property="og:url"
        content={process.env.NEXT_PUBLIC_DOMAIN_URL + url}
      />
      <meta property="article:location" content="region:arg" />
      <meta property="article:opinion" content={`${sectionId === "101"}`} />
      <meta
        itemprop="datePublished"
        content={getDateFormatForHead(publishedDate)}
      />
      {metas &&
        metas.map(({ tag, content }) => <meta name={tag} content={content} />)}
      <link rel="canonical" href={urlCanonical} />
      {relatedImageUrl && <link as="image" href={relatedImageUrl} />}
      {
        (metadata?.canHaveAmp || relAmp === true) && (
          <link rel="amphtml" href={process.env.NEXT_PUBLIC_DOMAIN_URL + url.replace('.html', '.amp.html')} />)
      }

      {shouldShowRSSLink &&
        <link rel="alternate" type="application/rss+xml" title={"RSS " + sectionName} href={"https://www.clarin.com/rss/" + sectionUrl} />
      }
    </Head>
  );
};